import React from 'react'
import { Table, Icon, Button, Input, Loader, Menu, Label } from 'semantic-ui-react'
import { strings } from '../core/strings';
import dateTimeFormatter from '../utils/dateTimeFormatter'

const status_label = [
    strings.status_preparing,
    strings.status_progress,
    strings.status_completed,
    strings.status_shipped
];

const status_label_color = [
    'grey',
    'yellow',
    'blue',
    'green'
];

const PrescriptionsTable = (props) => {

    let rows = props.prescriptions.map(p => {
        var time = p.time_requested != null ? p.time_requested.substring(0, 5) : "";
        return (
            <Table.Row textAlign='center' key={p.id_prescription}>
                <Table.Cell>{p.id_prescription} </Table.Cell>
                <Table.Cell>{p.patient_code}</Table.Cell>
                <Table.Cell>{p.description.substring(0,80)+"..."}</Table.Cell>
                <Table.Cell width='1'>{dateTimeFormatter(p.date_requested) + time}</Table.Cell>
                <Table.Cell width='2'><Label color={status_label_color[p.status]}>{status_label[p.status]}</Label></Table.Cell>
                <Table.Cell width='2'>{dateTimeFormatter(p.datetime)}</Table.Cell>
                <Table.Cell width='2'>
                    <Button className='third-color-background white-color' 
                             onClick={() => props.onEditPrescription(p.id_prescription)} >
                            <Icon name='edit outline'/>
                            {strings.action_prescription_button_label}
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    });

    let prev, next, page;

    if (props.pagination) {

        if (props.pagination.prev) {
            prev =
                <Menu.Item onClick={() => props.changePage(props.pagination.prev)} as='a' icon>
                    <Icon name='chevron left' />
                </Menu.Item>
        }
        if (props.pagination.next) {
            next =
                <Menu.Item onClick={() => props.changePage(props.pagination.next)} as='a' icon>
                    <Icon name='chevron right' />
                </Menu.Item>
        }
    }

    return (
        <div>
            <Loader active={props.loading} />
            <Table sortable celled structured>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell rowSpan='1'>{strings.table_id_prescription}
                            <p></p>
                            <Input style={{ width: "60px" }} size='mini' onChange={props.handleChangeCodFilter}>
                            </Input>
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_cod_patient}
                            <p></p>
                            <Input size='mini' onChange={props.handleChangeCodPatientFilter}></Input>
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>
                            {strings.table_prescription_description}
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={props.active.date_requested}
                            onClick={() => props.onChangeSort('date_requested')}
                            rowSpan='1'>{strings.table_prescription_date_requested} <br/>{strings.table_prescription_date_requested2}
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={props.active.status}
                            onClick={() => props.onChangeSort('status')}
                            rowSpan='1'>{strings.table_prescription_status}
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_prescription_datetime}<br/>{strings.table_prescription_datetime2}</Table.HeaderCell>
                        <Table.HeaderCell  rowSpan='1'></Table.HeaderCell>
                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' colSpan="11">
                            <Menu floated='right' pagination>
                                {prev}{next}
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>

    );
}


export default (PrescriptionsTable);

