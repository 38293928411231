import React, { Component } from 'react';
import { Grid, Segment, Form, Button, List, Image, Divider, Header, Message } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import {
    DateInput, TimeInput
} from 'semantic-ui-calendar-react-17';
import dateTimeFormatter from '../utils/dateTimeFormatter';

import DentalArch from './DentalArch';
import '../dental_arch.css'
import moment from 'moment';
import 'moment/locale/it';
import ErrorModal from '../utils/ErrorModal';
import OkModal from '../utils/OkModal';

const colorOptions = config.colors[config.BROWSER_LANG.substring(0, 2)].map(element => {
    var myEl = { key: element, text: element, value: element }
    return myEl;
})


class NewPrescription extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            patient_code: "",
            patient_gender: null,
            patient_age: 0,
            date_requested: "",
            time_requested: "",
            description: "",
            p_materials: [],
            tint: "",
            ref_prev_prescription: "",
            teeth: [],
            new_images: [],
            new_files: [],
            original_materials: [],
            materials: [],
            loading: false,
            error: false,
            ok: false,
            incomplete_submit: false

        }
        window.enabled = true;
        window.items = [];
        this.imageInputRef = React.createRef();
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getMaterials();
    }

    getMaterials = async () => {
        try {
            let response = await API.get_materials(this.state.accessToken);
            if (response.data.data != null) {
                var materials = response.data.data

                const new_materials = materials.map(item => {

                    var newEl = {};
                    if (config.BROWSER_LANG.substring(0, 2) == 'it') {
                        newEl.key = item.id_material;
                        newEl.value = item.name_it;
                        newEl.text = item.name_it;
                    } else if (config.BROWSER_LANG.substring(0, 2) == 'en') {
                        newEl.key = item.id_material;
                        newEl.value = item.name_en;
                        newEl.text = item.name_en;
                    } else {
                        newEl.key = item.id_material;
                        newEl.value = item.name_fr;
                        newEl.text = item.name_fr;
                    }

                    return newEl;
                })

                this.setState({
                    original_materials: materials,
                    materials: new_materials,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    clearAll = () => {
        this.setState({
            patient_code: "",
            date_requested: "",
            patient_age: 0,
            patient_gender: null,
            description: "",
            p_materials: [],
            tint: "",
            ref_prev_prescription: "",
            teeth: [],
            new_images: [],
            new_files: []
        })
        window.items = []; //cancello i denti colorati dell'oggetto dental arch
    }

    saveButton = () => {

        if (this.state.patient_code == "" ||
            this.state.date_requested == "" ||
            this.state.description == "" ||
            this.state.tint == "" ||
            this.state.p_materials.length == 0
        ) {
            this.setState({ incomplete_submit: true })
        } else {
            this.setState({ incomplete_submit: false })
            this.savePrescription();
        }

    }

    savePrescription = async () => {

        var prescription = {}

        prescription.patient_code = this.state.patient_code;
        prescription.patient_gender = this.state.patient_gender;
        prescription.patient_age = this.state.patient_age;
        prescription.description = this.state.description;
        prescription.tint = this.state.tint;
        prescription.ref_prev_prescription = this.state.ref_prev_prescription;
        prescription.date_requested = dateTimeFormatter(this.state.date_requested);
        prescription.time_requested = this.state.time_requested;

        prescription.materials = this.state.p_materials.map(name => {
            var m = this.state.original_materials.filter(function (item) {
                if (config.BROWSER_LANG.substring(0, 2) == 'it') {
                    return item.name_it === name;
                } else if (config.BROWSER_LANG.substring(0, 2) == 'en') {
                    return item.name_en === name;
                } else {
                    return item.name_fr === name;
                }
            }).map(function (item) { return item.id_material; });
            return m;
        });

        prescription.teeth = [...window.items];
        prescription.images = this.state.new_images;
        prescription.files = this.state.new_files;

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_prescription(this.state.accessToken, prescription);
            if (response.data.data == true) {
                // TODO handle error
                this.setState({
                    loading: false,
                    ok: true
                });
                this.clearAll();
                this.props.history.push("/" + config.MENU_ITEM_PRESCRIPTIONS + "/" + response.data.id_prescription)
            } else {
                this.setState({
                    loading: false,
                    error: true
                });
            }


        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false,
                error: true
            });
        }
    }

    handleChangePatienCode = (e, { value }) => {
        this.setState({ patient_code: value });
    }

    handleChangePatientAge = (e, { value }) => {
        this.setState({ patient_age: value });
    }

    handleChangeDateRequested = (e, { value }) => {
        // First check for the pattern
        if (/^\d{2}\-\d{2}\-\d{4}$/.test(value))
            this.setState({ date_requested: value.trim() });
    }

    handleChangeTimeRequested = (e, { value }) => {
        if (/^\d{2}\:\d{2}$/.test(value))
        this.setState({ time_requested: value.trim() });
    }

    handleChangeDescription = (e, { value }) => {
        this.setState({ description: value });
    }

    handleChangeTint = (e, { value }) => {
        this.setState({ tint: value });
    }

    handleChangeMaterials = (e, { value }) => {
        this.setState({ p_materials: value });
    }

    handleChangeRefPrevPrescription = (e, { value }) => {
        if (!isNaN(value)) {
            this.setState({ ref_prev_prescription: value });
        }
    }
    handleChangePatienGender = (value) => {
        this.setState({ patient_gender: value });
    }

    handleInputFileImage(e) {
        let files = e.target.files;

        var new_images = [...this.state.new_images];

        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (f) => {
                new_images.push(f.target.result);
                this.setState({
                    new_images: new_images
                })
            }
        }
    }

    handleInputFile(e) {
        let files = e.target.files;
        var new_files = [...this.state.new_files];

        for (let i = 0; i < files.length; i++) {
            const filename = files[i].name;
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);

            reader.onload = (f) => {
                const element = { name: filename, data: f.target.result }
                new_files.push(element);
                this.setState({
                    new_files: new_files
                })
            }
        }
    }

    render() {

        const new_images = this.state.new_images.map(image => {
            return (
                <Image src={image} label={{ as: 'a', color: 'grey', corner: 'left', icon: 'save', size: 'mini' }} />
            )
        });

        const new_files = this.state.new_files.map(file => {
            return (
                <List.Item key={file.name}>
                    <List.Icon name='file' />
                    <List.Content>
                        <List.Header as='a'>{file.name}</List.Header>
                    </List.Content>
                </List.Item>
            )
        });

        const imagesSegment =
            <Segment>
                <Header as='h4'>{strings.images_button_label}</Header>

                <Button
                    style={{ width: '190px' }}
                    content={strings.upload_new_image_label}
                    labelPosition="left"
                    icon="file"
                    onClick={() => this.imageInputRef.current.click()}
                />
                <input
                    ref={this.imageInputRef}
                    type="file"
                    hidden
                    onChange={(e) => this.handleInputFileImage(e)}
                    multiple
                />
                <Button
                    style={{ width: '190px' }}
                    content={strings.upload_new_file_label}
                    labelPosition="left"
                    icon="file"
                    onClick={() => this.fileInputRef.current.click()}
                />
                <input
                    ref={this.fileInputRef}
                    type="file"
                    hidden
                    onChange={(e) => this.handleInputFile(e)}
                    multiple
                />
                <p></p>

                <Image.Group size='medium'>
                    {new_images}
                </Image.Group>

                <p></p>

                <List>
                    {new_files}
                </List>
            </Segment>


        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={3}>
                        <SideMenu activeItem={config.MENU_ITEM_NEW_PRESCRIPTION} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Segment raised>

                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h2'> {strings.title_new_prescription} </Header>
                                        {this.state.prescription != null ? this.state.prescription.datetime : ''}
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        <Button icon="save" color='green' content={strings.send_prescription_button_label} onClick={() => this.saveButton()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <Form loading={this.state.loading} error={this.state.incomplete_submit} >
                                <Message
                                    error
                                    header={strings.attention}
                                    content={strings.message_error_mandatory_data}
                                />
                                <ErrorModal open={this.state.error} close={() => this.setState({ error: false })} />
                                <OkModal open={this.state.ok} close={() => this.setState({ ok: false })} />
                                <Grid stackable columns={2}>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center' width={6}>
                                            <div className='MyContainer'>
                                                <div id="canvas_container"> </div>
                                            </div>
                                            <DentalArch new_prescription={true} />
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <p></p>
                                            <Grid stackable columns={4}>
                                                <Grid.Row>
                                                    <Grid.Column width={10}>
                                                        <Form.Input required
                                                            value={this.state.patient_code}
                                                            label={strings.table_cod_patient} placeholder={strings.table_cod_patient}
                                                            onChange={this.handleChangePatienCode}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={3}>
                                                        <Form.Field>
                                                            <label> {strings.patient_gender} </label>
                                                            <Button.Group>
                                                                <Button onClick={() => this.handleChangePatienGender('M')} className={this.state.patient_gender == 'M' ? 'third-color-background white-color' : ''}>M</Button>
                                                                <Button onClick={() => this.handleChangePatienGender('F')} className={this.state.patient_gender == 'F' ? 'third-color-background white-color' : ''}>F</Button>
                                                            </Button.Group>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={3}>
                                                        <Form.Input type='number'
                                                            value={this.state.patient_age}
                                                            label={strings.patient_age} placeholder={strings.patient_age}
                                                            onChange={this.handleChangePatientAge}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid stackable columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Form.Field required>
                                                            <label> {strings.table_prescription_date_requested} </label>
                                                            <DateInput
                                                                localization='it'
                                                                name="date"
                                                                value={this.state.date_requested}
                                                                iconPosition="left"
                                                                onChange={this.handleChangeDateRequested}
                                                                minDate={new Date()}
                                                            />

                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Form.Field>
                                                            <label> {strings.table_prescription_time_requested} </label>
                                                            <TimeInput
                                                                localization='it'
                                                                name="time"
                                                                value={this.state.time_requested}
                                                                iconPosition="left"
                                                                onChange={this.handleChangeTimeRequested}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                </Grid.Row>
                                            </Grid>
                                            <p></p>
                                            <Form.TextArea required
                                                value={this.state.description}
                                                label={strings.table_prescription_description} placeholder={strings.table_prescription_description}
                                                onChange={this.handleChangeDescription}
                                            />
                                            <Form.Dropdown required
                                                label={strings.materials_label}
                                                clearable
                                                fluid
                                                multiple
                                                search
                                                selection
                                                value={this.state.p_materials}
                                                options={this.state.materials}
                                                placeholder={strings.materials_add_label}
                                                onChange={this.handleChangeMaterials}

                                            />
                                            <Form.Group widths='equal'>
                                                <Form.Dropdown required
                                                    label={strings.color_label}
                                                    placeholder={strings.color_label}
                                                    search selection options={colorOptions}
                                                    value={this.state.tint}
                                                    onChange={this.handleChangeTint}
                                                />
                                                <Form.Input
                                                    value={this.state.ref_prev_prescription}
                                                    label={strings.ref_prev_prescription}
                                                    placeholder={''}
                                                    onChange={this.handleChangeRefPrevPrescription}>
                                                </Form.Input>
                                            </Form.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <p></p>

                                {imagesSegment}

                                {/*<Button className='main-color-background white-color' onClick={() => this.savePrescription()} >
                                    <Icon name='save' /> {strings.save} </Button>*/}
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(NewPrescription)