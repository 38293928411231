import { strings } from './core/strings.js'

export const BASE_API_URL_LOCAL = 'http://sfl.local/api/v1.0/dentist/';
export const BASE_API_URL_DEV = 'https://staging.codicezero.com/sfl/api/v1.0/dentist/';
export const BASE_API_URL_PROD = 'https://api.fabiolevratto.com/v1.0/dentist/';
export const APP_VERSION = '1';
export const DEVICE_OS = 'web';
export const STATE = 'PROD'; 

export const MENU_ITEM_PRESCRIPTIONS = 'prescriptions';
export const MENU_ITEM_DENTIST = 'my';
export const MENU_ITEM_NEW_PRESCRIPTION = 'new_prescription';
export const MENU_ITEM_ACCOUNTING = 'accounting';

export const ROUTE_PRESCRIPTIONS_NEW = 'create-prescriptions';

export const SUPPORT_EMAIL = 'tech@codicezero.com';
export const ADMIN_EMAIL = 'fabio.levratto@monaco.mc';
export const ADMIN_PHONE = '+37799998994';

export const BROWSER_LANG = navigator.language || navigator.userLanguage;

export const colors = {
    "it": [
        "01", "1A", "2A", "1C", "2B", "1D", "1E", "2C", "3A", "5B", "2E", "3E", "4A", "6B", "4B", "6C",
        "6D", "4C", "3C", "4D", "A1", "A2", "A3", "A3,5", "A4", "B1", "B2", "B3", "B4", "BL1", "C1", "C2", "C3",
        "C4", "D2", "D3", "D4", "OM1", "OM2", "OM3", "1M1", "1M1,5", "1M2", "2L1,5", "2L2,5", "2M1","2M1,5", "2M2", "2M3",
        "2R2,5", "2R1,5", "3L1,5", "3L2,5", "3M1", "3M2", "3M3", "3R1,5", "3R2,5", "4L2,5", "4L1,5",
        "4M1", "4M2", "4M3", "4R1,5", "4R2,5", "5M1", "5M2", "VEDERE FOTO", "VEDERE CAMPIONE", "FILE/APPLICAZIONE"
    ],
    "en":[
        "01", "1A", "2A", "1C", "2B", "1D", "1E", "2C", "3A", "5B", "2E", "3E", "4A", "6B", "4B", "6C",
        "6D", "4C", "3C", "4D", "A1", "A2", "A3", "A3,5", "A4", "B1", "B2", "B3", "B4", "BL1", "C1", "C2", "C3",
        "C4", "D2", "D3", "D4", "OM1", "OM2", "OM3", "1M1", "1M1,5", "1M2", "2L1,5", "2L2,5", "2M1","2M1,5", "2M2", "2M3",
        "2R2,5", "2R1,5", "3L1,5", "3L2,5", "3M1", "3M2", "3M3", "3R1,5", "3R2,5", "4L2,5", "4L1,5",
        "4M1", "4M2", "4M3", "4R1,5", "4R2,5", "5M1", "5M2", "SEE PHOTOS", "SEE SAMPLE", "FILE/APPLICATION"
    ],
    "fr":[
        "01", "1A", "2A", "1C", "2B", "1D", "1E", "2C", "3A", "5B", "2E", "3E", "4A", "6B", "4B", "6C",
        "6D", "4C", "3C", "4D", "A1", "A2", "A3", "A3,5", "A4", "B1", "B2", "B3", "B4", "BL1", "C1", "C2", "C3",
        "C4", "D2", "D3", "D4", "OM1", "OM2", "OM3", "1M1", "1M1,5", "1M2", "2L1,5", "2L2,5", "2M1","2M1,5", "2M2", "2M3",
        "2R2,5", "2R1,5", "3L1,5", "3L2,5", "3M1", "3M2", "3M3", "3R1,5", "3R2,5", "4L2,5", "4L1,5",
        "4M1", "4M2", "4M3", "4R1,5", "4R2,5", "5M1", "5M2", "VOIR PHOTO", "VOIR L'ECHANTILLON", "FILE/APPLICATION"
    ]
};