import React from 'react'
import { Step, Segment, Progress, Header } from 'semantic-ui-react'
import completed from '../assets/status_images/status-completed.svg';
import preparing from '../assets/status_images/status-preparing.svg';
import progress from '../assets/status_images/status-progress.svg';
import shipped from '../assets/status_images/status-shipped.svg';
import { strings } from '../core/strings.js'

const StatusSteps = (props) => {

    const progressBar = props.status == 1 ? <Segment attached>
        <p></p>
        <Progress className='' progress percent={props.progress} color='blue'></Progress>
    </Segment> : null

    return (
        <div>
            <p></p>
            <Header as='h4'>{strings.progress_title}</Header>
            <Step.Group widths={4} fluid attached='top'>
                <Step active={props.status == 0 ? true : false}>
                    <i>
                        <img width={38} height={38} src={preparing} />
                    </i>&nbsp;&nbsp;&nbsp;

            <Step.Content>
                        <Step.Title>{strings.status_preparing}</Step.Title>
                        <Step.Description>{strings.status_preparing_description}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={props.status == 1 ? true : false}>
                    <i>
                        <img width={38} height={38} src={progress} />
                    </i>&nbsp;&nbsp;&nbsp;
            <Step.Content>
                        <Step.Title>{strings.status_progress}</Step.Title>
                        <Step.Description>{strings.status_progress_description}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={props.status == 2 ? true : false}>
                    <i>
                        <img width={38} height={38} src={completed} />
                    </i>&nbsp;&nbsp;&nbsp;
            <Step.Content>
                        <Step.Title>{strings.status_completed}</Step.Title>
                        <Step.Description>{strings.status_completed_description}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={props.status == 3 ? true : false}>
                    <i>
                        <img width={38} height={38} src={shipped} />
                    </i>&nbsp;&nbsp;&nbsp;
            <Step.Content>
                        <Step.Title>{strings.status_shipped}</Step.Title>
                        <Step.Description>{strings.status_shipped_description}</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            {progressBar}

        </div>
    )

}

export default StatusSteps