import React, { Component } from 'react';
import { Grid, Segment,  Loader } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import PrescriptionsTable from './PrescriptionTable';

class Prescriptions extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            prescriptions: [],
            pagination: "",
            loading: false,
            openConfirmRequestAlert: false,
            openRejectRequestAlert: false,
            currentRequestId: null,
            userMessage: null,
            orderby: null,
            active: {
                date_requested: null,
                status: null
            },
            filters: []
        };
    }

    changeSort = (value) => {
        let new_active = Object.assign({}, this.state.active);

        let new_order = '';
        Object.keys(new_active).forEach(function (key) {
            if (key == value) {
                new_active[key] = new_active[key] === 'ascending' ? 'descending' : 'ascending';
                new_order = new_active[key] === 'ascending' ? 'asc' : 'desc';
            } else {
                new_active[key] = null;
            }
        });

        const new_orderby = "order_by_" + value + "_" + new_order;

        this.setState({
            orderby: new_orderby,
            active: new_active
        });

        this.getPrescriptions(new_orderby);
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    componentDidMount() {
        this.getPrescriptions(this.state.orderby);
    }

    getPrescriptions = async (orderBy, searchFilters = []) => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_prescriptions(this.state.accessToken, orderBy, searchFilters);
            if (response.data.data != null) {
                const prescriptions = [...response.data.data]
                const pagination = Object.assign({}, response.data.pagination)

                this.setState({
                    prescriptions: prescriptions,
                    pagination: pagination,
                    loading: false
                });

            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('DENTIST_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    changePage = async (page) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_page(page);
            const prescriptions = [...response.data.data];
            const pagination = response.data.pagination;

            this.setState({
                prescriptions: prescriptions,
                pagination: pagination,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    onEditPrescription = async (id) => {
        this.routeChange(config.MENU_ITEM_PRESCRIPTIONS + "/" + id)
    }

    handleChangeCodPatientFilter = (e, { value }) => {
        var filters = Object.assign({}, this.state.filters);
        filters['patient_code'] = value
        this.setState({
            filters: filters
        })
        this.getPrescriptions(this.state.orderby, filters);

    }

    handleChangeCodFilter = (e, { value }) => {
        var filters = Object.assign({}, this.state.filters);

        filters['id_prescription'] = value
        this.setState({
            filters: filters
        })
        this.getPrescriptions(this.state.orderby, filters);
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={3}>
                        <SideMenu activeItem={config.MENU_ITEM_PRESCRIPTIONS} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Segment raised>
                            <NavigationHeader title={strings.prescriptions}></NavigationHeader>

                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />

                            <PrescriptionsTable
                                handleChangeCodFilter={this.handleChangeCodFilter}
                                handleChangeCodPatientFilter={this.handleChangeCodPatientFilter}
                                prescriptions={this.state.prescriptions}
                                pagination={this.state.pagination}
                                onEditPrescription={(id) => this.onEditPrescription(id)}
                                changePage={this.changePage}
                                onChangeSort={(value) => this.changeSort(value)}
                                active={this.state.active}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>


            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(Prescriptions)