import React, { Component } from 'react';
import { Grid, Segment, Header, Menu, Icon, Input, Loader, Divider, GridColumn, GridRow, Item } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import dateTimeFormatter from '../utils/dateTimeFormatter';


const paymentOptions = [
    { key: 'paid', text: strings.paid, value: true },
    { key: 'free', text: strings.free, value: false },
]

class Accounting extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            prescriptions: [],
            pagination: "",
            loading: false,
            payment: 'free',
            cod_prescription: '',
            workslist: [],
            modalOpen: null,
            current_works: [],
            new_additional_cost_description: '',
            new_additional_cost_price: 0
        };
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    componentDidMount() {
        this.getPrescriptions();
        this.getWorkslist();
    }

    getWorkslist = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_workslist(this.state.accessToken, true);
            if (response.data.data != null) {
                const workslist = [...response.data.data]

                this.setState({
                    workslist: workslist,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    getPrescriptions = async (cod_prescription) => {

        var searchFilters = [];
        var orderBy = 'order_by_date_requested_desc';
        if (cod_prescription != null) {
            searchFilters['id_prescription'] = cod_prescription;
        } else {
            searchFilters['id_prescription'] = this.state.cod_prescription;
        }

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_prescriptions(this.state.accessToken, orderBy, searchFilters, 10, true);
            if (response.data.data != null) {
                const prescriptions = [...response.data.data]
                const pagination = Object.assign({}, response.data.pagination)

                this.setState({
                    prescriptions: prescriptions,
                    pagination: pagination,
                    loading: false
                });

            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    handleChangePayment = (id_prescription, id_task, e, { value }) => {

        const prescriptions = this.state.prescriptions.map(p => {

            if (p.id_prescription == id_prescription) {
                p.tasks = p.tasks.map(t => {
                    if (t.id_task == id_task) {
                        t.payment = value;
                    }
                    return t;
                })
            }
            return p;

        }
        )

        this.setState({
            prescriptions: prescriptions
        });

        this.updateTaskPayment(id_task, value);
    }

    confirmPayment = async (id_prescription) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_prescription_payment(this.state.accessToken, id_prescription);

            this.setState({
                loading: false
            });

            this.getPrescriptions();


        } catch (error) {
            // TODO handle error

            this.setState({
                loading: false
            });
            this.getPrescriptions();
        }
    }


    updateTaskPayment = async (id_task, payment) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_payment(this.state.accessToken, id_task, payment);

            this.setState({
                loading: false
            });

            if (response.data.data == null || response.data.data === false) {
                this.getPrescriptions();
            }

        } catch (error) {
            // TODO handle error

            this.setState({
                loading: false
            });
            this.getPrescriptions();
        }
    }

    handleChangeInvoiceDescription = (id_task, e, { value }) => {

        //this.saveInvoiceDescription(id_task, value);
    }
    handleChangeBatchNumber = (id_task, id_material, e, { value }) => {

        this.saveBatchNumber(id_task, id_material, value);
    }

    handleChangeWorkCode = (id_task, e, { value }) => {

        this.saveTaskWorkcode(id_task, value);
    }

    handleChangeDiscount = (id_task, e, { value }) => {

        this.saveTaskDiscount(id_task, value);
    }

    handleChangeNewAdditionalCostDescription = (e, { value }) => {

        this.setState({
            new_additional_cost_description: value
        })
    }

    handleChangeNewAdditionalCostPrice = (e, { value }) => {

        this.setState({
            new_additional_cost_price: value
        })
    }

    saveTaskDiscount = async (id_task, discount) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_discount(this.state.accessToken, id_task, discount);

            this.setState({
                loading: false
            });


        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    saveTaskWorkcode = async (id_task, work_code) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_workcode(this.state.accessToken, id_task, work_code);

            this.setState({
                loading: false
            });

            if (this.state.cod_prescription != '') {
                this.getPrescriptions(this.state.cod_prescription)
            } else {
                this.getPrescriptions();
            }
        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    saveInvoiceDescription = async (id_task, description) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_invoice_description(this.state.accessToken, id_task, description);

            this.setState({
                loading: false
            });

        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    saveBatchNumber = async (id_task, id_material, value) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_material_batch_number(this.state.accessToken, id_task, id_material, value);

            this.setState({
                loading: false
            });

        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    handleChangePrescriptionCode = (e, { value }) => {
        this.setState({ cod_prescription: value });
        this.getPrescriptions(value);
    }

    changePage = async (page) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_page(page);
            const prescriptions = [...response.data.data];
            const pagination = response.data.pagination;

            this.setState({
                prescriptions: prescriptions,
                pagination: pagination,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    handleChangeCurrentWork = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            id_work: data.value,
            quantity: data.quantity,
            discount: data.discount,
            payment: data.payment
        }
        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleAddNewCurrentWork = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            id_work: data.value,
            quantity: 1,
            discount: 0,
            payment: 1
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);
        if (result === -1) {
            current_works.push(el)

        }

        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkQuantity = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.value,
            id_work: data.id_work,
            discount: data.discount,
            payment: data.payment
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkPayment = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.quantity,
            id_work: data.id_work,
            discount: data.discount,
            payment: data.value
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkDiscount = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.quantity,
            id_work: data.id_work,
            discount: data.value,
            payment: data.payment
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);
        if (result === -1) {
            current_works.push(el)

        } else {
            current_works[result] = el
        }

        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentTask = (task) => {

        this.setState({
            current_task_id: task.id_task,
            current_works: task.works
        });
    }

    handleDeleteCurrentWork = (w) => {

        var current_works = [...this.state.current_works];

        const result = current_works.findIndex(element => element.id_work == w.id_work);
        if (result === -1) {
        } else {
            current_works.splice(result, 1);

        }
        this.setState({ current_works: current_works });

    }

    handleSaveTaskWorks = async (id_task) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_task_works(
                this.state.accessToken,
                id_task,
                this.state.current_works
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    new_modalOpen: false
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    handleAddAdditionalCost = async (id_prescription) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_additional_cost(
                this.state.accessToken,
                id_prescription,
                this.state.new_additional_cost_description,
                this.state.new_additional_cost_price
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    handleDeleteAdditionalCost = async (id_additional_cost) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.delete_additional_cost(
                this.state.accessToken,
                id_additional_cost
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    render() {

        let prescriptions = this.state.prescriptions.map(p => {

            let message;

            let addition_costs = p.additional_costs.map(a => {
                if (a.price != 0) {
                    return (
                        <GridRow key={a.id_additional_cost}>
                            <GridColumn width={10}>
                                <b> {a.description}</b>
                            </GridColumn>
                            <GridColumn textAlign='center' width={3}>
                                -
                            </GridColumn>
                            <GridColumn textAlign='center' width={3}>
                                <b>  {a.price}</b>
                            </GridColumn>
                        </GridRow>

                    )
                }
            });

            var works = p.tasks.map(t => {

                let works = t.works.map(w => {

                    const index = this.state.workslist.map(e => e.id_work).indexOf(w.id_work);
                    var localized_work = '';
                    var code = '';
                    if (this.state.workslist[index] != undefined) {
                        localized_work = this.state.workslist[index].name_it;
                        code = this.state.workslist[index].code;
                        if (config.BROWSER_LANG.substring(0, 2) == 'fr') {
                            localized_work = this.state.workslist[index].name_fr;
                        } else if (config.BROWSER_LANG.substring(0, 2) == 'en') {
                            localized_work = this.state.workslist[index].name_en;
                        }
                    }


                    return (
                        <GridRow>
                            <GridColumn width={10}>
                                <b> {code + " - " + localized_work}</b>
                            </GridColumn>
                            <GridColumn textAlign='center' width={3}>
                                {w.quantity}
                            </GridColumn>
                            <GridColumn textAlign='center' width={3}>
                                <b>  {w.price}</b>
                            </GridColumn>
                        </GridRow>

                    )
                })
                return works;
            });

            const livraison_url = p.livraison_url != null ? p.livraison_url.substring(p.livraison_url.lastIndexOf('/') + 1, p.livraison_url.indexOf('?')) : '';

            return (
                <Segment raised key={p.id_prescription}>
                    <Header as='h3'>
                        {strings.title_prescription} {p.id_prescription + " ( " + dateTimeFormatter(p.datetime.substring(0, 10)) + ")"}
                    </Header>
                    <Segment>
                        <Grid divided stackable columns={3}>
                            <Grid.Row>
                                <Grid.Column width={10}>
                                    <b>{strings.work}</b>
                                </Grid.Column>
                                <Grid.Column width={3} textAlign='center' >
                                    <b>{strings.quantity}</b>
                                </Grid.Column>
                                <Grid.Column width={3} textAlign='center' >
                                    <b>{strings.price}</b>
                                </Grid.Column>
                            </Grid.Row>
                            <Divider className='divider-no-margin' fitted></Divider>
                        </Grid>

                        <Grid divided stackable columns={3}>
                            {works}
                            {addition_costs}
                        </Grid>
                    </Segment>

                    <Segment.Group basic horizontal color='green' >
                        <Segment textAlign='left'>
                            <Item.Content verticalAlign='middle'>
                                <Item.Header as='a' href={p.livraison_url}>
                                    <Icon size='large' name='file' />
                                    {livraison_url}
                                </Item.Header>
                            </Item.Content>
                        </Segment>
                        <Segment textAlign='right'>
                            <Header>{strings.total}: {p.price}  € </Header>
                        </Segment>
                    </Segment.Group>
                    {message}
                </Segment>
            )
        });

        let prev, next, page;

        if (this.state.pagination) {

            if (this.state.pagination.prev) {
                prev =
                    <Menu.Item onClick={() => this.changePage(this.state.pagination.prev)} as='a' icon>
                        <Icon name='chevron left' />
                    </Menu.Item>
            }
            if (this.state.pagination.next) {
                next =
                    <Menu.Item onClick={() => this.changePage(this.state.pagination.next)} as='a' icon>
                        <Icon name='chevron right' />
                    </Menu.Item>
            }
        }

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={3} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ACCOUNTING} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Segment raised>
                            <NavigationHeader title={strings.accounting}></NavigationHeader>
                            <Input
                                value={this.state.cod_prescription}
                                icon='search' placeholder={strings.table_id_prescription2}
                                onChange={this.handleChangePrescriptionCode}
                            />
                            <Loader active={this.state.loading} disabled={!this.state.loading} />
                            {prescriptions}
                            <div style={{ textAlign: 'right' }}>
                                <Menu pagination>
                                    {prev}{next}
                                </Menu>
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid>


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withCookies,
    withRouter
)(Accounting)