import React, { Component } from 'react';
import { Grid, Segment, List, Form, Button, Header, Loader, Divider } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import ErrorModal from '../utils/ErrorModal';
import OkModal from '../utils/OkModal';

const languageOptions = [
    { key: 'it', text: 'it', value: 'it' },
    { key: 'fr', text: 'fr', value: 'fr' },
    { key: 'en', text: 'en', value: 'en' }
];

class Dentist extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            dentist: null,
            loading: false,
            old_password: '',
            new_password: '',
            language: '',
            confirm_new_password: '',
            error: false,
            error_generic: false,
            error2: false,
            error3: false,
            ok: false
        };
    }

    componentDidMount() {
        this.getDentist()
    }

    saveChanges = async () => {
        this.setState({
            loading: true,
        })

        try {
            const response = await API.put_dentist(this.state.accessToken,
                this.state.old_password,
                this.state.new_password,
                this.state.language);

            if (response.data.data == true) {
                this.setState({
                    loading: false,
                    ok: true
                });
            } else if (response.data.error.code == 12100) {
                this.setState({
                    loading: false,
                    error2: true
                });
            } else {
                this.setState({
                    loading: false,
                    error_generic: true
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                error_generic: true
            });
        }
    }


    getDentist = async () => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.loginToken(this.state.accessToken);
            if (response.data.data != null) {
                const dentist = response.data.data

                this.setState({
                    dentist: dentist,
                    language: dentist.language,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    handleChangeNewPassword = (e, { value }) => {
        this.setState({ new_password: value });
    }

    handleChangeOldPassword = (e, { value }) => {
        this.setState({ old_password: value });
    }


    handleChangeConfirmNewPassword = (e, { value }) => {
        this.setState({ confirm_new_password: value });
    }

    handleChangeLanguage = (e, { value }) => {
        this.setState({ language: value });
    }

    handleSaveClick = () => {

        if (this.state.new_password.length < 6) {
            this.setState({ error3: true });
        }
        else {
            if (this.state.confirm_new_password === this.state.new_password && (this.state.new_password.length >= 6 || this.state.new_password.length == 0)) {
                this.saveChanges();
            } else {

                this.setState({ error: true });
            }
        }
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={3}>
                        <SideMenu activeItem={config.MENU_ITEM_DENTIST} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Segment raised>
                            <ErrorModal error_message={strings.message_password_not_equal} open={this.state.error} close={() => this.setState({ error: false })} />
                            <ErrorModal open={this.state.error_generic} close={() => this.setState({ error_generic: false })} />
                            <ErrorModal error_message={strings.message_error_wrong_credentials} open={this.state.error2} close={() => this.setState({ error2: false })} />
                            <ErrorModal error_message={strings.message_password_limit} open={this.state.error3} close={() => this.setState({ error3: false })} />

                            <OkModal open={this.state.ok} close={() => this.setState({ ok: false })} />
                            <Header as='h2'> {this.state.dentist !== null ? this.state.dentist.description : ''} </Header>
                            <Divider />
                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />

                            <List>
                                <List.Item>
                                    <List.Header as='h3'>{this.state.dentist !== null ? this.state.dentist.name + " " + this.state.dentist.surname : ''}</List.Header>
                                    {strings.owner}
                                </List.Item>
                                <List.Item>
                                    <List.Header as='h3'>{this.state.dentist !== null ? this.state.dentist.email : ''}</List.Header>
                                    {strings.email}
                                </List.Item>
                                <List.Item>
                                    <List.Header as='h3'>{this.state.dentist !== null ? this.state.dentist.phone_number : ''}</List.Header>
                                    {strings.phone}
                                </List.Item>
                                <List.Item>
                                    <List.Header as='h3'>{this.state.dentist !== null ? this.state.dentist.address : ''}</List.Header>
                                    {strings.address}
                                </List.Item>
                            </List>

                            <Divider />
                            <Form>
                                <Header as='h3'>{strings.edit_text_button}</Header>
                                <Form.Input width={6} type='password'
                                    label={strings.old_password} placeholder={strings.old_password}
                                    onChange={this.handleChangeOldPassword} />
                                <Form.Input width={6} type='password'
                                    label={strings.new_password} placeholder={strings.new_password}
                                    onChange={this.handleChangeNewPassword} />
                                <Form.Input width={6} type='password'
                                    label={strings.confirm_new_password} placeholder={strings.confirm_new_password}
                                    onChange={this.handleChangeConfirmNewPassword} />

                                <Form.Dropdown width={6}
                                    label={strings.language}
                                    placeholder={strings.language}
                                    search selection options={languageOptions}
                                    value={this.state.language}
                                    onChange={this.handleChangeLanguage}
                                />
                                <Button className='third-color-background white-color' onClick={() => this.handleSaveClick()}>{strings.save_new_password}</Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies
)(Dentist)