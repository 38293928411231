import axios from 'axios';
import * as config from '../Config';

class API {

    constructor() {
        switch (config.STATE) {
            case 'PROD': this.baseUrl = config.BASE_API_URL_PROD; break;
            case 'DEV': this.baseUrl = config.BASE_API_URL_DEV; break;
            case 'LOCAL': this.baseUrl = config.BASE_API_URL_LOCAL; break;
            default: this.baseUrl = config.BASE_API_URL_LOCAL; break;
        }

    }

    b64toBlob = (dataURI) => {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpg' });
    }

    login = (email, password) => {

        var bodyFormData = new FormData();
        bodyFormData.set('email', email);
        bodyFormData.set('password', password);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'login',
            method: 'post',
            data: bodyFormData
        });
    }

    loginToken = (token) => {

        let params = { token: token };

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'login_token',
            params: params,
            method: 'post'
        });
    }

    logout = (token) => {
        return axios({
            method: 'delete',
            url: this.baseUrl + 'logout',
            params: {
                token: token
            }
        });
        //location.reload(true);
    }

    get_prescriptions = (token, orderby, searchFilters, payment = null) => {
        let params = { token: token };
        if(payment != null){
            params.payment = true;
        }
        
        if (orderby != null) {
            params[orderby] = 1;
        }
        for (var index in searchFilters) {
            if (searchFilters[index] != '') {
                params[index] = searchFilters[index];
            }
        }

        return axios({
            method: 'get',
            url: this.baseUrl + 'prescriptions',
            params: params
        });
    }

    get_prescription = (token, id) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'prescriptions/' + id,
            params: params
        });
    }

    get_materials = (token) => {

        let params = { token: token };

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'materials',
            params: params,
            method: 'get'
        });
    }

    get_page = (page) => {

        if (config.STATE == 'LOCAL') {
            page = page.slice(0, 4) + page.slice(5);
        }

        return axios.get(page, {
        });
    }

    put_dentist = (token, old_password, new_password, language) => {

        var params = new URLSearchParams();

        if (old_password != '') {
            params.append('old_password', old_password);
        }
        if (new_password != '') {
            params.append('new_password', new_password);
        }

        params.append('language', language);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl.slice(0, -1),
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    get_workslist = (token, complete) => {

        if(complete !== true){
            complete = false
        }

        return axios({
            url: this.baseUrl + 'workslist',
            method: 'get',
            params: {
                token: token,
                complete:complete
            }
        });
    }

    put_dentist_password = (token, old_password, new_password) => {


        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl.slice(0, -1) + "/new_password",
            method: 'get',
            params: {
                token: token,
                old_password: old_password,
                new_password: new_password
            }
        });
    }

    post_prescription = (token, prescription) => {

        var bodyFormData = new FormData();
        bodyFormData.set('patient_code', prescription.patient_code);
        bodyFormData.set('patient_gender', prescription.patient_gender);
        bodyFormData.set('patient_age', prescription.patient_age);
        bodyFormData.set('description', prescription.description);
        bodyFormData.set('date_requested', prescription.date_requested);
        prescription.time_requested = prescription.time_requested != "" ? prescription.time_requested : null;
        bodyFormData.set('time_requested', prescription.time_requested);
        if (prescription.ref_prev_prescription != null && prescription.ref_prev_prescription != '') {
            bodyFormData.set('ref_prev_prescription', prescription.ref_prev_prescription);
        }
        bodyFormData.set('tint', prescription.tint);
        bodyFormData.set('web', true);

        prescription.materials.forEach(id => {
            bodyFormData.append('ids_materials[]', id);
        });

        prescription.images.forEach((image, i) => {
            bodyFormData.set('img' + (i + 1), image);
        });

        prescription.files.forEach((file, i) => {
            bodyFormData.set('file' + (i + 1) + '_data', file.data);
            bodyFormData.set('file' + (i + 1) + '_name', file.name);
        });

        prescription.teeth.forEach((t, i) => {
            bodyFormData.append('ids_teeth[]', t);
        });

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'prescriptions',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    put_prescription = (token, prescription) => {

        var bodyFormData = new URLSearchParams();
        bodyFormData.set('patient_code', prescription.patient_code);
        bodyFormData.set('patient_gender', prescription.patient_gender);
        bodyFormData.set('patient_age', prescription.patient_age);
        bodyFormData.set('description', prescription.description);
        bodyFormData.set('date_requested', prescription.date_requested);
        bodyFormData.set('time_requested', prescription.time_requested);
        if (prescription.ref_prev_prescription != null && prescription.ref_prev_prescription != '') {
            bodyFormData.set('ref_prev_prescription', prescription.ref_prev_prescription);
        }
        bodyFormData.set('tint', prescription.tint);
        bodyFormData.set('web', true);
        bodyFormData.set('id_prescription', prescription.id_prescription);

        prescription.materials.forEach(id => {
            bodyFormData.append('ids_materials[]', id);
        });

        prescription.images.forEach((image, i) => {
            bodyFormData.set('img' + (i + 1), image);
        });

        prescription.files.forEach((file, i) => {
            bodyFormData.set('file' + (i + 1) + '_data', file.data);
            bodyFormData.set('file' + (i + 1) + '_name', file.name);
        });

        prescription.teeth.forEach((t, i) => {
            bodyFormData.append('ids_teeth[]', t);
        });

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'prescriptions/' + prescription.id_prescription,
            method: 'put',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }


    delete_prescription = (token, prescriptionId) => {

        return axios({
            url: this.baseUrl + 'prescriptions/' + prescriptionId,
            method: 'delete',
            params: {
                token: token
            }
        });
    }

    reset_password = (email) => {
        let params = { email: email, lang: config.BROWSER_LANG.substring(0, 2) };

        return axios({
            method: 'get',
            url: this.baseUrl + 'reset_password',
            params: params
        });
    }

}
export default (new API());