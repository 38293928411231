import React from 'react'
import '../dental_arch.css'
import { Loader } from 'semantic-ui-react';

const DentalArch = (props) => {

    return (
        <div>
            {document.getElementById("canvas_container") != null ? document.getElementById("canvas_container").innerHTML = "" : <Loader className='custom-loader' active={true} />}
            {document.getElementById("canvas_container") != null ? window.init() : null}
        </div>
    )

}

export default DentalArch