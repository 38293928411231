
//import * as actionTypes from '../actions';

const initialState = {
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default userReducer;