import React, { Component } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import "../App.css";
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import Root from './Root';
import Dentist from './Dentist';
import Prescriptions from './Prescriptions';
import Prescription from './Prescription';
import * as config from '../Config';
import NewPrescription from './NewPrescription';
import Accounting from './Accounting';

class Home extends Component {
    renderPrescription = (routerProps) => {
        let prescriptionId = routerProps.match.params.prescriptionId
        return <Prescription prescriptionId={prescriptionId} />
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path={"/" + config.MENU_ITEM_ACCOUNTING}>
                        <Accounting />
                    </Route>
                    <Route path={"/" + config.MENU_ITEM_DENTIST}>
                        <Dentist />
                    </Route>
                    <Route exact path={"/" + config.MENU_ITEM_PRESCRIPTIONS + "/:prescriptionId"} render={this.renderPrescription} ></Route>
                    <Route path={"/" + config.MENU_ITEM_PRESCRIPTIONS}>
                        <Prescriptions />
                    </Route>
                    <Route exact path={"/" + config.MENU_ITEM_NEW_PRESCRIPTION } >
                        <NewPrescription/>
                    </Route>
                    <Route path="/">
                        <Root />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default compose(
    withCookies
)(Home)