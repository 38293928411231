const tokenCookie = (props) => {
    
    const { cookies } = (props != null ? props : {});

    const accessToken = cookies.get('DENTIST_TOKEN', true);

    return accessToken;

}

export default tokenCookie;