import React from 'react'
import { Button, Modal, List, Icon } from 'semantic-ui-react'
import { strings } from '../core/strings';

function ReportModal(props) {
    const [open, setOpen] = React.useState(false)

    const onClick = () => {
        props.onSend();
        setOpen(false);
    }

    const list_items = props.reports.map(report => {
        return (
            <List.Item key={report.id_report}>
                <List.Icon name='talk' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='h4'>{report.report}</List.Header>
                    <List.Description>{report.datetime}</List.Description>
                </List.Content>
            </List.Item>
        )
    })

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button color='orange' className='blink_me'>
                    <Icon name='exclamation triangle' /> {props.action_button_content != undefined ? props.action_button_content : strings.edit_text_button}
                </Button>
            }
        >
            <Modal.Header>{strings.report_text_button}</Modal.Header>

            <Modal.Content >
                <List divided relaxed>
                    {list_items}
                </List>

            </Modal.Content>
            <Modal.Actions>
                <Button className='secondary-color-background' onClick={() => setOpen(false)}>
                    Chiudi
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ReportModal
