import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    prescriptions: "Prescriptions",
    back: 'back',
    user: "User",
    new_prescription: "Add prescription",
    logout: "Logout",
    table_id_prescription: "Code",
    table_cod_patient: "Patient code",
    table_prescription_description: "Description",
    table_prescription_date_requested: "Date ",
    table_prescription_time_requested: "Time",
    table_prescription_date_requested2: "requested",
    table_prescription_status: "Status",
    table_prescription_datetime: "Datetime",
    table_prescription_datetime2: "",
    status_preparing: "Preparing",
    status_preparing_description: "Working program is not already defined",
    status_progress: "Working",
    status_progress_description: "Working in progress. Check the progress bar!",
    status_completed: "Completed",
    status_completed_description: "Working process is completed. We are going to deliver soon",
    status_shipped: "Shipped",
    status_shipped_description: "Package is delivered",
    progress_title: "Working Progress",
    ref_prev_prescription: "Related prescription code",
    go_text_button: "Go",
    edit_text_button: "Edit",
    materials_label: "Materials",
    materials_add_label: "Add materials",
    color_label: "Color",
    images_button_label: "Images",
    upload_new_image_label: "Upload new image",
    upload_new_file_label: "Upload new file",
    support: "Tech support",
    admin: "Laboratory",
    new_password: "Nuova password",
    confirm_new_password: "Nuova password",
    old_password: "Vecchia password",
    save_new_password: "Salva modifiche",
    owner: "Owner",
    email: "Email",
    phone: "Phone",
    address: "Address",
    title_prescription: "Prescrizione n°",
    title_new_prescription: "New prescription",
    send_prescription_button_label: "Send prescription",
    save_prescription_button_label: "Save prescription",
    cancel_prescription_button_label: "Cancel",
    action_prescription_button_label: "See",
    delete_prescription_button_label: "Delete",
    message_error_standard: "An error occured. Please try later.",
    message_error_wrong_credentials: "Old password is not correct.",
    message_action_ok: "OK",
    message_confirmation_standard: "Operation successfully completed",
    message_lose_data: "Are you sure to continue? You will lose all unsaved data",
    message_action_goon: "Go on!",
    message_action_giveup: "Cancel",
    message_password_not_equal: "The two version of new password are not equal",
    message_password_ok: "New password has been set correctly",
    message_password_limit: "Password must have at least 6 characters",
    message_error_mandatory_data: "Please set all mandatory fields",
    attention: "Attention",
    report_text_button: 'Send message',
    language: "Comunications language",
    certificate:'Certificate',
    download: "Download",
    no_certificate: "No certificate.",
    patient_gender: "Patient gender",
    patient_age: "Patient Age",
    start_work: "Start work date",

    accounting: 'Accounting',
    work: "Work",
    quantity: "Quantity",
    price: "Price (€)",
    total: "Total",
    ask_last_version: "Ask for the lastest version",
    update_version: "Version update"

  },
  it: {
    prescriptions: "Elenco prescrizioni",
    back: 'Indietro',
    user: "Profilo",
    new_prescription: "Richiedi prescrizione",
    logout: "Logout",
    table_id_prescription: "Codice",
    table_cod_patient: "Codice paziente",
    table_prescription_description: "Descrizione",
    table_prescription_time_requested: "Ora di consegna",
    table_prescription_date_requested: "Data di consegna",
    table_prescription_date_requested2: "richiesta",
    table_prescription_status: "Status",
    table_prescription_datetime: "Data/ora ",
    table_prescription_datetime2: "inoltro",
    status_preparing: "In preparazione",
    status_preparing_description: "A breve verrà definito il programma di lavoro",
    status_progress: "In lavorazione",
    status_progress_description: "La prescrizione è in lavorazione",
    status_completed: "Completato",
    status_completed_description: "La lavorazione è completata.",
    status_shipped: "Spedito",
    status_shipped_description: "Il pacco è stato consegnato al corriere",
    progress_title: "Progresso della lavorazione",
    ref_prev_prescription: "Codice prescrizione precedente",
    go_text_button: "Vai",
    edit_text_button: "Modifica",
    materials_label: "Materiali",
    materials_add_label: "Aggiungi materiali",
    color_label: "Colore",
    images_button_label: "Immagini e file",
    upload_new_image_label: "Carica nuova foto",
    upload_new_file_label: "Carica nuovo file",
    support: "Supporto tecnico",
    admin: "Laboratorio",
    new_password: "Nuova password",
    confirm_new_password: "Ripeti nuova password",
    old_password: "Vecchia password",
    save_new_password: "Salva modifiche",
    owner: "Titolare",
    email: "Email",
    phone: "Telefono",
    address: "Indirizzo",
    title_prescription: "Prescrizione n°",
    title_new_prescription: "Nuova prescrizione",
    send_prescription_button_label: "Invia prescrizione",
    save_prescription_button_label: "Salva",
    cancel_prescription_button_label: "Annulla",
    action_prescription_button_label: "Entra",
    delete_prescription_button_label: "Elimina",
    message_error_standard: "Si è verificato un errore. Riprovare più tardi. Se il problema persiste contattare il supporto tecnico.",
    message_error_wrong_credentials: "La vecchia password non è corretta.",
    message_action_ok: "OK",
    message_confirmation_standard: "Operazione avvenuta con successo.",
    message_lose_data: "Sei sicuro di continuare? Perderai tutti i dati non salvati.",
    message_action_goon: "Continua",
    message_action_giveup: "Annulla",
    message_password_not_equal: "Le due versioni della nuova password non coincidono.",
    message_password_ok: "La nuova password è stata settata correttamente.",
    message_password_limit: "La password deve avere almeno 6 caratteri.",
    message_error_mandatory_data: "Non sono stati valorizzati tutti i campi obbligatori.",
    attention: "Attenzione",
    report_text_button: 'Segnalazioni',
    language: "Lingua comunicazioni",
    certificate:'Certificato',
    download: "Scarica",
    no_certificate: "Il certificato è assente.",
    patient_gender: "Genere paziente",
    patient_age: "Età paziente",
    start_work: "Data inizio lavori",
    accounting: 'Contabilità',
    work: "Lavorazione",
    quantity: "Quantità",
    price: "Prezzo (€)",
    total: "Totale",
    ask_last_version: "Richiedi l'ultima versione",
    update_version: "Aggiorna la versione"
  },
  fr: {
    accounting: 'Comptabilité',
    today: 'Aujourd’hui',
    prescriptions: "Liste de prescriptions",
    back: 'En arrière',
    user: "Profil",
    new_prescription: "Demande de prescription",
    logout: "Déconnexion",
    table_id_prescription: "Code",
    table_id_prescription2: "Code prescription",
    table_cod_patient: "Code patient",
    table_prescription_description: "Description",
    table_prescription_time_requested: "Heure",
    table_prescription_date_requested: "Date de livraison",
    table_prescription_date_requested2: "Demande",
    table_prescription_date_internal: "Date de fin travail",
    table_prescription_status: "Statut",
    table_prescription_datetime: "Date/heure ",
    table_prescription_datetime2: "Tranfert",
    table_dentist_id: "Id",
    table_dentist_email: "Mail",
    table_dentist_name: "Prénom",
    table_dentist_surname: "Nom",
    table_dentist_description: "Dentiste",
    table_dentist_address: "Adresse",
    table_dentist_password: "Mot de passe initiale (devra être modifié au premier accès) ",
    table_dentist_phone: "Téléphone",
    table_user_id: "Id",
    table_user_email: "Mail",
    table_user_name: "Prénom",
    table_user_surname: "Nom",
    table_user_role: "Rôle",
    table_user_password: "Mot de passe",
    table_operator_id: "Id",
    table_operator_image: "Image",
    table_operator_lab: "Laboratoire",
    table_operator_name: "Prénom",
    table_operator_surname: "Nom",
    table_operator_inspector: "Contrôle",
    status_preparing: "En préparation",
    status_preparing_description: "Le programme de travail sera défini prochainement",
    status_progress: "En cours de travail",
    status_progress_description: "La prescription est en cours de traitement",
    status_completed: "Statut terminé",
    status_completed_description: "Le traitement est complété.",
    status_shipped: "Envoyé",
    status_shipped_description: "Le colis à été remis au coursier",
    progress_title: "Progression du traitement",
    progress_prescription: "Progression de prescription",
    ref_prev_prescription: "Code prescription précedente",
    go_text_button: "Suivant",
    edit_text_button: "Modifier",
    edit_task_title: "Modifier Tâche",
    new_task_title: "Créer nouvelle Tâche",
    materials_label: "Materiaux",
    materials_add_label: "Ajouter materiaux",
    color_label: "Teinte",
    images_button_label: "Visualiser les images et fichiers",
    images_button_label_close: "Cacher les images et fichiers",
    upload_new_image_label: "Charger nouvelle photo",
    upload_new_file_label: "Charger nouveau fichier",
    support: "Support technique",
    admin: "Laboratoire",
    new_password: "Nouveau mot de passe",
    confirm_new_password: "Répété nouveau mot de passe",
    old_password: "Ancien mot de passe",
    save_new_password: "Sauvegarder modifications",
    owner: "Titulaire",
    email: "Mail",
    email: "Mail",
    role: "Rôle",
    phone: "Téléphone",
    address: "Adresse",
    title_prescription: "Prescription n°",
    title_new_prescription: "Nouvelle prescription",
    send_prescription_button_label: "Envoyer prescription",
    save_prescription_button_label: "Sauvegarder",
    cancel_prescription_button_label: "Annuler",
    action_prescription_button_label: "Entrer",
    delete_prescription_button_label: "Eliminer",
    title_admin: "Profil utilisateur",
    users_manager: "Gestion utilisateurs",
    dentists_manager: "Gestion praticiens",
    operators_manager: "Gestion operateurs",
    edit_password_label: "Modifier mot de passe",
    report_text_button: "Rapport",
    dentists_registry: "Registre praticiens",
    dentist_title: "Dentiste id:",
    add_dentist_button: "Ajouter nouveau praticiens",
    add_dentist_close_button: "Annuler",
    save_dentist_button: "Sauvegarder",
    users_registry: "Registre utilisateurs interne",
    add_user_button: "Ajouter nouvel utilisateur interne",
    add_user_close_button: "Annuler",
    user_title: "Id d’utilisateur:",
    save_user_button: "Sauvegarder",
    operators_registry: "Registre operateurs",
    operator_title: "Id operateur:",
    add_operator_button: "Ajouter nouvel operateur",
    add_task_button: "Ajouter nouvelle tâche",
    add_operator_close_button: "Annuler",
    save_operator_button: "Sauvegarder",
    role_secretary: 'Secrétariat',
    role_admin: 'Administration',
    planning_title: "Programmation",
    plan_title: "Programme",
    inspection_label: "Contrôle",
    send_report_title: "Envoyer rapport au client",
    message_error_standard: "Une erreur est survenue. Réessayez plus tard. Si le problème persiste, contactez le support technique.",
    message_error_wrong_credentials: "L’ancien mot de passe est incorrecte.",
    message_action_ok: "OK",
    message_confirmation_standard: "Operation terminé avec succès.",
    message_lose_data: "Êtes-vous sûr de vouloir continuer? Vous perdrez toutes les données non enregistrées.",
    message_action_goon: "Continuer",
    message_action_giveup: "Annuler",
    message_password_not_equal: "Les deux version du nouveau mot de passe ne correspondent pas.",
    message_password_ok: "Le nouveau mot de passe a été défini correctement.",
    message_password_limit: "Le nouveau mot de passe a été au moins 6 caractères.",
    message_error_mandatory_data: "Veuillez remplir toutes les données obligatoires.",
    attention: "Attention",
    paid: "À payer",
    free: "À ne pas payer",
    payment_label: "Paiement",
    ongoing: "En cours",
    login: "Acceder",
    password_forgotten_message: "Si vous avez oublié votre mot de passe et souhaitez le réinitialiser, cliquez ici !",
    start_datetime: "Démarrer",
    end_datetime: "Fin",
    no_task: "Il n’y a pas de tâches assignées",
    dashboard: "Tableau de bord",
    confirm: "Confirmation",
    language: "Langage de communication",
    certificate:'Certificat',
    download:'Télécharger',
    no_certificate: "Le certificat est absent.",
    patient_gender: "Genre patient",
    patient_age: "Âge patient",
    start_work: "Date de début de travail",
    accounting: 'Comptabilité',
    work: "Travail",
    quantity: "Quantité",
    price: "Prix (€)",
    total: "Total",
    ask_last_version: "Demander la dernière version",
    update_version: "Version de mise à jour"
  }
})
export { strings };