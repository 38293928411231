import React from 'react'
import { Divider, Grid, Header } from 'semantic-ui-react'
import "../App.css";

const NavigationHeader = (props) => (
    <div>
    <Grid columns={3}>
        <Grid.Row textAlign='left' >
            <Grid.Column verticalAlign='middle'>
                <Header textAlign='left' as='h2'>{props.title} </Header>
            </Grid.Column>
            <Grid.Column verticalAlign='middle' >
            </Grid.Column>
            <Grid.Column verticalAlign='middle' >
            </Grid.Column>
        </Grid.Row>
    </Grid>
    <Divider></Divider>
    </div>
)

export default NavigationHeader