import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { compose } from 'recompose';
import { Segment, Container, Button, Image, Header, Item } from 'semantic-ui-react';
import { Icon, Menu } from 'semantic-ui-react'
import { strings } from '../core/strings.js'
import * as config from '../Config';
import logo from '../assets/logo-header.png'
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';

class SideMenu extends Component {

    /*static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };*/

    constructor(props) {
        super(props);

        const authToken = tokenCookie(props);

        this.state = {
            loading: false,
            authToken: authToken
        }

    }

    routeChange = (path) => {
        this.props.history.push("/" + path);
    }

    onLogoutClick = async () => {
        this.setState({
            loading: true
        });
        try {
            let response = await API.logout(this.state.authToken);
            const { cookies } = this.props;
            cookies.remove('DENTIST_TOKEN');
            this.props.history.push("/");
            this.setState({
                loading: false
            });

        } catch (error) {
            this.setState({
                loading: false
            });
            alert("LOGOUT ERROR");
        }
    }

    clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload(false);
      };

    render() {
        return (
            <div>
                <Segment raised textAlign='center'>
                    <Container >
                        <Image style={{ maxWidth: 80 }} height='50' src={logo} as='a' href="" />
                    </Container>
                    <Menu size='massive' secondary stackable fluid icon='labeled' vertical>

                        <Menu.Item
                            name={config.MENU_ITEM_PRESCRIPTIONS}
                            active={this.props.activeItem === config.MENU_ITEM_PRESCRIPTIONS}
                            onClick={() => this.routeChange(config.MENU_ITEM_PRESCRIPTIONS)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_PRESCRIPTIONS ? 'main-color' : 'secondary-color'} name='list' />
                            <p className={this.props.activeItem === config.MENU_ITEM_PRESCRIPTIONS ? 'main-color' : 'secondary-color'}>{strings.prescriptions}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_NEW_PRESCRIPTION}
                            active={this.props.activeItem === config.MENU_ITEM_NEW_PRESCRIPTION}
                            onClick={() => this.routeChange(config.MENU_ITEM_NEW_PRESCRIPTION)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_NEW_PRESCRIPTION ? 'main-color' : 'secondary-color'} name='wpforms' />
                            <p className={this.props.activeItem === config.MENU_ITEM_NEW_PRESCRIPTION ? 'main-color' : 'secondary-color'}>{strings.new_prescription}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_DENTIST}
                            active={this.props.activeItem === config.MENU_ITEM_DENTIST}
                            onClick={() => this.routeChange(config.MENU_ITEM_DENTIST)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_DENTIST ? 'main-color' : 'secondary-color'} name='doctor' />
                            <p className={this.props.activeItem === config.MENU_ITEM_DENTIST ? 'main-color' : 'secondary-color'}>{strings.user}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_ACCOUNTING}
                            active={this.props.activeItem === config.MENU_ITEM_ACCOUNTING}
                            onClick={() => this.routeChange(config.MENU_ITEM_ACCOUNTING)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_ACCOUNTING ? 'main-color' : 'secondary-color'} name='list' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_ACCOUNTING ? 'main-color' : 'secondary-color'}>{strings.accounting}</p>
                        </Menu.Item>
                    </Menu>
                    <Button loading={this.state.loading} fluid icon labelPosition='right' onClick={this.onLogoutClick}>
                        <Icon name='sign-out' />
                        {strings.logout}
                    </Button>
                </Segment>
                <Segment textAlign='center' raised>
                    <Header as='h5' dividing>{strings.admin}</Header>
                    <p>Le Copori <br/>
                    9 Avenue Albert II 98000 Monaco MC<br/> 
                    Principautè de Monaco </p>

                    <Item icon='tel' content={<a href={"tel:" + config.ADMIN_PHONE} target="_blank" >{config.ADMIN_PHONE}</a>} />
                    <Item icon='mail' content={<a href={"mailto:" + config.ADMIN_EMAIL} target="_blank" >{config.ADMIN_EMAIL}</a>} />
                </Segment>
                <Segment textAlign='center' raised>
                    <Header as='h5' dividing>{strings.support}</Header>
                    <Item icon='mail' content={<a href={"mailto:" + config.SUPPORT_EMAIL} target="_blank" >{config.SUPPORT_EMAIL}</a>} />
                </Segment>
                <Segment textAlign='center' raised>
                    <Header as='h5' dividing>{strings.update_version}</Header>
                    <Button onClick={() => this.clearCacheData()} > {strings.ask_last_version}</Button>
                </Segment>
            </div>
        )
    }
}

export default compose(
    withCookies,
    withRouter)(SideMenu);